import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/atoms/Seo";
import SliceWelcome from "../components/organisms/SliceWelcome";
import SliceTwoColTextButton from "../components/organisms/SliceTwoColTextButton";
import SliceThreeColText from "../components/organisms/SliceThreeColText";
import SliceNewsPreview from "../components/organisms/SliceNewsPreview";

function Post({ data }) {
  const post = data.prismicPost.data;
  return (
    <>
      <Seo title={post.title.text} />
      {post.body.map((slice) => {
        if (slice.slice_type === "welcome") {
          return <SliceWelcome key={slice.id} slice={slice.primary} />;
        } else if (slice.slice_type === "three_col_text") {
          return (
            <SliceThreeColText
              key={slice.id}
              slice={slice.primary}
              stats={slice.items}
            />
          );
        } else if (slice.slice_type === "news_preview") {
          return <SliceNewsPreview key={slice.id} posts={slice.items} />;
        } else if (slice.slice_type === "two_col_text_button") {
          return (
            <SliceTwoColTextButton
              key={slice.id}
              slice={slice.primary}
              font_colour={slice.primary.font_colour}
              bg_colour={slice.primary.bg_colour}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default Post;

export const query = graphql`
  query ($id: String!) {
    prismicPost(id: { eq: $id }) {
      data {
        title {
          text
        }
        body {
          ... on PrismicPostDataBodyWelcome {
            id
            slice_type
            primary {
              title {
                text
              }
              hero_image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              image_headline {
                gatsbyImageData
                alt
              }
              description_headline {
                text
              }
              description {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyNewsPreview {
            id
            slice_type
            items {
              post {
                uid
                document {
                  ... on PrismicPost {
                    id
                    data {
                      title {
                        text
                      }
                      preview_description {
                        richText
                      }
                      preview_image {
                        gatsbyImageData
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPostDataBodyTwoColTextButton {
            id
            slice_type
            primary {
              bg_colour
              font_colour
              button_styling
              title {
                text
              }
              description {
                richText
              }
              button_display_text {
                text
              }
              button {
                slug
                uid
                target
              }
            }
          }
          ... on PrismicPostDataBodyThreeColText {
            id
            slice_type
            primary {
              column_1 {
                text
              }
              column_image {
                alt
                gatsbyImageData
              }
              column_2 {
                richText
              }
              column_3 {
                richText
              }
              partnership_logo {
                alt
                gatsbyImageData
              }
              partnership_title {
                text
              }
              show_stats
              kinfo {
                text
              }
              kinfo2 {
                text
              }
              border_colour
            }
            items {
              percentage {
                text
              }
              percentage_topic {
                text
              }
            }
          }
        }
      }
    }
  }
`;
